import React, {useState, useEffect} from 'react';
import {Button, Grid, TextField, Typography, CircularProgress} from "@mui/material";
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import {makeStyles} from '@mui/styles';
import axios from "axios";
import Header from "./header";

const format = "DD/MM/yyyy";

const useStyles = makeStyles({
    container: {
        "&.MuiGrid-root.MuiGrid-item": {
            padding: 16,
            marginRight: 0,
            marginTop: 0,
        },
    },
    textArea: {
        backgroundColor: "#67185db0",
    },
    textField: {
        "& .MuiOutlinedInput-input": {
            backgroundColor: "#67185db0",
        },
    },
    item: {
        "&.MuiGrid-root.MuiGrid-item": {
            marginTop: 16,
            paddingRight: 0,
        },
    },
    transparent: {
        color: 'transparent',
        fontSize: 1,
        display: 'block',
    },
    root: {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderWidth: "medium",
            borderColor: "#d3bd4b",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#d3bd4b",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: "medium",
            borderColor: "#d3bd4b",
        },
        "& .MuiOutlinedInput-input": {
            color: "white",
            fontSize: 20,
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "white",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "white",
        },
        "& .MuiInputLabel-root": {
            top: "-5px",
        },
        "& .MuiInputLabel-outlined": {
            color: "white",
            fontSize: 20,
        },
        "&:hover .MuiInputLabel-outlined": {
            color: "white",
            fontSize: 20,
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "white",
            fontSize: 25,
        },
        "& .MuiInputLabel-shrink, & fieldset": {
            fontSize: 25,
        },
        "&:hover .MuiInputLabel-shrink, & fieldset": {
            fontSize: 25,
        },
        "& .MuiFormHelperText-root": {
            color: "red",
        },
    },
    loading: {
        "&.MuiCircularProgress-root": {
            color: "#d3bd4b",
            margin: 5,
        }
    },
});

function Main({onSuccess}) {
    const classes = useStyles();
    const recaptchaRef = React.createRef();

    const [name, setName] = useState("");
    const [date, setDate] = useState(moment());
    const [birth, setBirth] = useState(moment());
    const [mobile, setMobile] = useState("");
    const [confirmMobile, setConfirmMobile] = useState("");
    const [count, setCount] = useState("");
    const [comment, setComment] = useState("");
    const [recaptcha, setRecaptcha] = useState(null);
    const [error, setError] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const textarea = document.getElementsByClassName('MuiOutlinedInput-input MuiInputBase-input MuiInputBase-inputMultiline')
        for (const element of textarea) {
            element.setAttribute("aria-label", "בקשות מיוחדות");
        }
    }, []);

    const nameValidation = (name) => (!name || !name.length) && "שדה חובה";
    const mobileValidation = (mobile) => (!mobile || !mobile.length) && "שדה חובה";
    const confirmMobileValidation = (confirmMobile) => {
        if (!confirmMobile || !confirmMobile.length) {
            return "שדה חובה";
        }
        if (mobile !== confirmMobile) {
            return "נייד לא זהה";
        }
        return undefined;
    }
    const countValidation = (count) => (!count || !count.length) && "שדה חובה";
    const recaptchaValidation = (recaptcha) => (!recaptcha || !recaptcha.length) &&
        "גם אני פעם יצאתי עם רובוטית, עזוב, תסמן...";
    const dateValidation = (date) => (!date || !date.isValid()) && "תאריך הזמנה לא תקין";
    const birthValidation = (birth) => (!birth || !birth.isValid()) && "תאריך לידה לא תקין";

    const validation = () => {
        const errors = {
            name: nameValidation(name),
            mobile: mobileValidation(mobile),
            confirmMobile: confirmMobileValidation(confirmMobile),
            count: countValidation(count),
            recaptcha: recaptchaValidation(recaptcha),
            date: dateValidation(date),
            birth: birthValidation(birth),
        };
        setErrors(errors);
        const invalid = Object.values(errors).find(error => !!error);
        if (invalid) {
            setError("נא למלא את החסר");
            return false;
        }
        setError(null);
        return true;
    }

    const submit = () => {
        if (!validation() || loading) {
            return;
        }
        setError(null);
        setLoading(true);

        const recaptchaState = recaptchaRef.current;
        axios.post(`/api/reserve`, {
            name, mobile, count, comment, date, birth, recaptcha
        })
            .then(res => {
                onSuccess();
            })
            .catch(error => {
                setLoading(false);
                setError("שגיאת מערכת, אנא נסה שנית מאוחר יותר");
                recaptchaState.reset();
                setRecaptcha(null);
            });
    }

    const onName = (event) => {
        const newName = event.target.value;
        setName(newName);
        if (!!errors.name) {
            setErrors({
                ...errors,
                name: nameValidation(newName),
            });
            setError(null);
        }
    }

    const onMobile = (event) => {
        const newMobile = event.target.value.replace(/[^0-9]/g, '');
        setMobile(newMobile);
        if (!!errors.mobile) {
            setErrors({
                ...errors,
                mobile: mobileValidation(newMobile),
            });
            setError(null);
        }
    }

    const onConfirmMobile = (event) => {
        const newConfirmMobile = event.target.value.replace(/[^0-9]/g, '');
        setConfirmMobile(newConfirmMobile);
        if (!!errors.confirmMobile) {
            setErrors({
                ...errors,
                confirmMobile: confirmMobileValidation(newConfirmMobile),
            });
            setError(null);
        }
    }

    const onCount = (event) => {
        const newCount = event.target.value.replace(/[^0-9]/g, '');
        setCount(newCount);
        if (!!errors.count) {
            setErrors({
                ...errors,
                count: countValidation(newCount),
            });
            setError(null);
        }
    }

    const onComment = (event) => setComment(event.target.value);

    return (
        <Grid item
              container
              role="main"
              className={classes.container}>

            <Header xs={12}/>

            <Grid item xs={12} className={`${classes.item}`}>
                <TextField
                    aria-label="שם מלא"
                    label="שם מלא"
                    value={name}
                    onSelect={onName}
                    onChange={onName}
                    className={`${classes.root} ${classes.textField}`}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={!!errors.name}
                    helperText={!!errors.name && errors.name}
                />
            </Grid>

            <Grid item xs={12} className={`${classes.item}`}>
                <TextField
                    aria-label="נייד"
                    label="נייד"
                    value={mobile}
                    onSelect={onMobile}
                    onChange={onMobile}
                    className={`${classes.root} ${classes.textField}`}
                    InputLabelProps={{shrink: true}}
                    error={!!errors.mobile}
                    helperText={!!errors.mobile && errors.mobile}
                />
            </Grid>

            <Grid item xs={12} className={`${classes.item}`}>
                <TextField
                    aria-label="אימות נייד"
                    label="אימות נייד"
                    value={confirmMobile}
                    onSelect={onConfirmMobile}
                    onChange={onConfirmMobile}
                    className={`${classes.root} ${classes.textField}`}
                    InputLabelProps={{shrink: true}}
                    error={!!errors.confirmMobile}
                    helperText={!!errors.confirmMobile && errors.confirmMobile}
                />
            </Grid>

            <Grid item xs={12} className={`${classes.item}`}>
                <MobileDatePicker
                    showToolbar={false}
                    aria-label="תאריך לידה"
                    label="תאריך לידה"
                    inputFormat={format}
                    value={birth.toDate()}
                    onChange={(newBirth) => {
                        setBirth(newBirth);
                        if (!!errors.birth) {
                            setErrors({
                                ...errors,
                                birth: birthValidation(newBirth),
                            });
                            setError(null);
                        }
                    }}
                    okText={"אישור"}
                    cancelText={"ביטול"}
                    renderInput={(params) =>
                        <TextField className={`${classes.root} ${classes.textField}`}
                                   InputLabelProps={{shrink: true}}
                                   {...params} />
                    }
                    error={!!errors.birth}
                    helperText={!!errors.birth && errors.birth}
                />
            </Grid>

            <Grid item xs={12} className={`${classes.item}`}>
                <MobileDatePicker
                    showToolbar={false}
                    aria-label="למתי?"
                    label="למתי?"
                    inputFormat={format}
                    value={date.toDate()}
                    onChange={(newDate) => {
                        setDate(newDate);
                        if (!!errors.date) {
                            setErrors({
                                ...errors,
                                date: dateValidation(newDate),
                            });
                            setError(null);
                        }
                    }}
                    okText={"אישור"}
                    cancelText={"ביטול"}
                    renderInput={(params) =>
                        <TextField className={`${classes.root} ${classes.textField}`}
                                   InputLabelProps={{shrink: true}}
                                   {...params}
                        />
                    }
                    error={!!errors.date}
                    helperText={!!errors.date && errors.date}
                />
            </Grid>

            <Grid item xs={12} className={`${classes.item}`}>
                <TextField
                    aria-label="כמה תהיו?"
                    label="כמה תהיו?"
                    value={count}
                    onSelect={onCount}
                    onChange={onCount}
                    className={`${classes.root} ${classes.textField}`}
                    InputLabelProps={{shrink: true}}
                    error={!!errors.count}
                    helperText={!!errors.count && errors.count}
                />
            </Grid>

            <Grid item xs={12} className={`${classes.item}`}>
                <TextField
                    label="בקשות מיוחדות"
                    multiline
                    rows={3}
                    value={comment}
                    onSelect={onComment}
                    onChange={onComment}
                    className={`${classes.root} ${classes.textArea}`}
                    InputLabelProps={{shrink: true}}
                />
            </Grid>

            <Grid container
                  item
                  xs={12}
                  direction="column"
                  alignItems="center"
                  className={`${classes.item}`}>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={"6LchbHsfAAAAAHMRzZh0rB_BQ-1gah-4qc67VG92"}
                    onChange={(newRecaptcha) => {
                        setRecaptcha(newRecaptcha);
                        if (!!errors.recaptcha) {
                            setErrors({
                                ...errors,
                                recaptcha: recaptchaValidation(newRecaptcha),
                            });
                            setError(null);
                        }
                    }}
                    onExpired={() => setRecaptcha(null)}
                    onErrored={() => setRecaptcha(null)}
                    style={{display: 'inline-block'}}
                />
                <Typography
                    variant="subtitle1"
                    component="span"
                    color={"red"}
                    fontSize={14}>
                    {!!errors.recaptcha && errors.recaptcha}
                </Typography>
            </Grid>

            <Grid container
                  item
                  xs={12}
                  direction="column"
                  alignItems="center"
                  className={`${classes.item}`}>
                <Button
                    sx={{
                        "&:hover": {
                            borderWidth: "medium",
                            borderColor: "#d3bd4b",
                            color: "white",
                            fontSize: 20,
                            backgroundColor: "#67185db0",
                            borderRadius: "8px",
                            width: "140px",
                        },
                        "&.MuiButton-text": {color: "#808080"},
                        borderWidth: "medium",
                        borderColor: "#d3bd4b",
                        color: "white",
                        fontSize: 20,
                        backgroundColor: "#67185d",
                        borderRadius: "8px",
                        width: "140px",
                    }}
                    variant="outlined"
                    onClick={submit}>
                    {loading ?
                        <CircularProgress size={25} className={`${classes.loading}`}/> :
                        <label aria-label="שלח">שלח</label>
                    }
                </Button>
                <Typography
                    variant="subtitle1"
                    component="span"
                    color={"red"}
                    fontSize={14}>
                    {!!error && error.toString()}
                </Typography>
            </Grid>

            <Grid role="region" item xs={12} className={`${classes.item}`}>
                <Typography variant="subtitle1" component="div" color={"white"} fontSize={20}>
                    * ההגעה עד השעה 23:00
                </Typography>
                <label className={classes.transparent}
                       aria-label="recaptcha"
                       htmlFor="g-recaptcha-response"
                >recaptcha</label>
            </Grid>

        </Grid>
    );
}

export default Main;
