import React, {useState, useEffect} from "react";
import "./App.css";
import Main from "./components/main";
import Success from "./components/success";
import {makeStyles} from "@mui/styles";
import main_background from './images/main_background.png';
import success_background from './images/success_background.png';
import {Grid} from "@mui/material";
import Statement from './components/statement';
import {Accessibility} from 'accessibility';

const useStyles = makeStyles({
    container: {
        textAlign: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundAttachment: "fixed",
        flex: 1,
        width: "calc(100% + 1px)",
        height: "calc(100% + 1px)",
        minHeight: "100%",
        backgroundColor: "#201c2c",
    },
});

const PAGE_NAME = {
    MAIN: "main",
    SUCCESS: "success",
};

const PAGE_BACKGROUND = {
    [PAGE_NAME.MAIN]: main_background,
    [PAGE_NAME.SUCCESS]: success_background,
};

function App() {
    const classes = useStyles();
    const [page, setPage] = useState(PAGE_NAME.MAIN);
    const [openStatement, setOpenStatement] = useState(false);
    let accessibility;

    useEffect(() => {
        if (!accessibility) {
            accessibility = new Accessibility({
                menu: {
                    dimensions: {
                        height: {size: 70, units: 'vh'}
                    },
                },
                session: {
                    persistent: false,
                },
                icon: {
                    circular: true,
                    img: 'accessible',
                    position: {
                        bottom: {
                            size: 10,
                            units: 'px',
                        },
                        left: {
                            size: 10,
                            units: 'px',
                        },
                    },
                },
                textPixelMode: true,
                modules: {
                    decreaseText: true,
                    increaseText: true,
                    invertColors: true,
                    increaseTextSpacing: true,
                    decreaseTextSpacing: true,
                    grayHues: true,
                    underlineLinks: true,
                    bigCursor: true,
                    readingGuide: true,
                    textToSpeech: true,
                    speechToText: true,
                    disableAnimations: true
                },
                language: {
                    textToSpeechLang: 'he-IL',
                    speechToTextLang: 'he-IL'
                },
                labels: {
                    menuTitle: 'נגישות',
                    increaseText: 'הגדלת גופן',
                    decreaseText: 'הקטנת גופן',
                    increaseTextSpacing: 'הגדלת ריווח בין מילים',
                    decreaseTextSpacing: 'הקטנת ריווח בין מילים',
                    increaseLineHeight: 'הגדלת ריווח בין שורות',
                    decreaseLineHeight: 'הקטנת ריווח בין שורות',
                    invertColors: 'ניגודיות',
                    grayHues: 'מונוכרום',
                    underlineLinks: 'הדגשת קישורים',
                    bigCursor: 'הגדלת סמן העכבר',
                    readingGuide: 'מדריך קריאה',
                    disableAnimations: 'ביטול הנפשות',
                    speechToText: 'פקודות קוליות',
                    textToSpeech: 'הקראת טקסט'
                },
                customFunctions: [{
                    method: () => {
                        accessibility.toggleMenu();
                        setOpenStatement(true);
                    },
                    buttonText: 'הצהרת נגישות',
                    id: 'accessibility',
                    icon: ' ',
                }],
            });
            setTimeout(() => {
                const menu = document.getElementsByClassName('_access-menu');
                if (menu.length) {
                    menu[0].style.display = 'inline-grid';
                }
            }, 2000);
        }
    }, []);

    return (
        <>
            <div className={classes.container} style={{backgroundImage: `url(${PAGE_BACKGROUND[page]})`}}>

                <Grid container
                      justifyContent="space-between"
                      direction={"row"}
                      style={{width: "100%", margin: 0, minHeight: "100vh"}}>

                    {page === PAGE_NAME.MAIN && <Main onSuccess={() => setPage(PAGE_NAME.SUCCESS)}/>}
                    {page === PAGE_NAME.SUCCESS && <Success/>}

                </Grid>

            </div>
            <Statement open={openStatement} onClose={() => setOpenStatement(false)}/>
        </>
    );
}

export default App;
