import React from 'react';
import company_logo from '../images/company_logo.png';
import {makeStyles} from '@mui/styles';
import {Grid} from "@mui/material";

const useStyles = makeStyles({
    container: {
        "&.MuiGrid-root.MuiGrid-item": {
            paddingRight: 0,
        },
    },
    company_logo: {
        height: "35vmin",
        pointerEvents: "none",
    },
});

function Header({xs}) {
    const classes = useStyles();
    return (
        <Grid item xs={xs} className={`${classes.container}`}>
            <img src={company_logo} className={classes.company_logo} alt="company_logo"/>
        </Grid>
    );
}

export default Header;
