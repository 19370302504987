import React from 'react';
import {Box, Grid, IconButton, Modal, Typography} from "@mui/material";

import {makeStyles} from '@mui/styles';

const useStyles = makeStyles({
    title: {
        alignSelf: "flex-end",
    },
    close: {
        alignSelf: "flex-start",
    },
});

function Statement({open, onClose}) {
    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="הצהרת נגישות"
            aria-describedby="מודל הצהרת נגישות"
            sx={{
                width: '100vw',
                height: '100vh',
                overflowX: 'auto',
                position: 'absolute',
            }}
        >
            <Box sx={{
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                m: 4,
            }}>
                <Grid container direction="row" justifyContent="space-between">
                    <Typography
                        className={classes.title}
                        variant="h6"
                        aria-label="title"
                        component="h2">
                        הצהרת נגישות
                    </Typography>
                    <IconButton
                        className={classes.close}
                        aria-label="close"
                        onClick={onClose}>
                        ✖️
                    </IconButton>
                </Grid>
                <Box>
                    <Typography sx={{mt: 2}}>אתר זה מייחס חשיבות רבה להנגשת השימוש בו לכלל האוכלוסייה ועושה כל שביכולתו
                        להבטיח שמשתמשים בעלי מוגבלויות יוכלו להפיק את המיטב מהתכנים המוצגים בו.</Typography>
                    <Typography sx={{mt: 2}}>הגלישה במצב נגישות תומכת בכל סוגי הדפדפנים הקיימים היום.</Typography>
                    <Typography sx={{mt: 2}}>באתר זה מוטמע תוסף נגישות המסייע בהנגשת האתר לבעלי מוגבלויות.</Typography>
                    <Typography sx={{mt: 2, fontWeight: 'bold', textDecoration: 'underline'}}>
                        מדריך למשתמש בתפריט:
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        לאחר הלחיצה על כפתור הנגישות ייפתחו בפניכם תפריטי תוסף הנגישות, באמצעותם תוכלו:
                    </Typography>
                    <Typography sx={{mt: 1}}>• לשנות את גודל הגופן</Typography>
                    <Typography>• לשנות ריווח בין מילים</Typography>
                    <Typography>• לשנות ריווח בין שורות</Typography>
                    <Typography>• לשנות את תצוגת האתר</Typography>
                    <Typography>• לשנות את צבעי האתר</Typography>
                    <Typography>• מצב ניגודיות גבוהה עבור כבדי ראייה</Typography>
                    <Typography>• מצב מונוכרום שחור לבן עבור עיוורי צבעים</Typography>
                    <Typography>• הדגשת קישורים באתר</Typography>
                    <Typography>• הגדלת סמן עכבר</Typography>
                    <Typography>• הוספת מדריך קריאה</Typography>
                    <Typography>• לעצור הנפשות (אנימציות) באתר</Typography>
                    <Typography>• הפעלת פקודות קוליות מאפשרת למשתמש להזין טקסט באמצעות דיבור המתורגם ע״י
                        הדפדפן</Typography>
                    <Typography>• הפעלת הקראת טקסט מאפשרת למשתמש לבחור כל טקסט באתר שאותו הוא מעוניין לשמוע ע״י תוכנת
                        השמע של הדפדפן</Typography>
                    <Typography>• פקודות קוליות והקראת טקסט מופעלים בדפדפנים נתמכים ובשפה העיברית בלבד</Typography>
                    <Typography>•  איפוס המבטל את הנגישות</Typography>
                    <Typography sx={{mt: 2}}>אנו ממשיכים במאמצים לשפר את נגישות האתר, ככל האפשר, וזאת מתוך אמונה
                        ומחויבות מוסרית לאפשר שימוש באתר לכלל האוכלוסייה לרבות אנשים עם מוגבלויות.</Typography>
                    <Typography sx={{mt: 2}}>במידה ונתקלתם בקושי בגלישה באתר וצפייה בתוכנו אנו מתנצלים ונשמח מאוד כי תפנו את תשומת ליבנו לכך.</Typography>
                    <Typography sx={{mt: 2}}>פניות בנושא נגישות ניתן לפנות במייל: fedyukr@gmail.com</Typography>
                    <Typography sx={{mt: 2}}>עדכון הצהרת נגישות: 04.07.2017</Typography>
                </Box>
            </Box>
        </Modal>
    );
}

export default Statement;
