import React, {useEffect} from 'react';
import {Grid, Typography} from "@mui/material";
import {makeStyles} from '@mui/styles';
import Header from "./header";

const useStyles = makeStyles({
    container: {
        "&.MuiGrid-root.MuiGrid-item": {
            padding: 16,
        },
    },
});

function Success() {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (

        <Grid item
              container
              direction={"column"}
              className={classes.container}>

            <Header xs={3}/>

            <Grid container
                  item
                  xs={"auto"}
                  direction="column"
                  alignItems="center"
                  style={{paddingRight: 0}}>
                <Typography
                    variant="subtitle1"
                    component="span"
                    color={"white"}
                    fontSize={22}
                    alignSelf={"center"}
                    justifySelf={"center"}
                    style={{
                        marginTop: 40,
                        borderColor: "#d3bd4b",
                        borderWidth: "medium",
                        borderStyle: "solid",
                        borderRadius: "8px",
                        padding: 20,
                        backgroundColor: "#67185db0",
                    }}>

                    <pre style={{fontFamily: 'inherit', margin: 0}}>{"בקשתך נשלחה"}</pre>
                    <pre style={{fontFamily: 'inherit', margin: 0}}>{"בהצלחה!"}</pre>
                    <pre style={{fontFamily: 'inherit', margin: 0}}>{"ניצור איתך קשר"}</pre>
                    <pre style={{fontFamily: 'inherit', margin: 0}}>{"בקרוב..."}</pre>
                    <pre style={{fontFamily: 'fangsong', margin: "7px 0 -7px 0"}}>{"SEE YOU"}</pre>
                    <pre style={{fontFamily: 'fangsong', margin: 0}}>{"SHAMA SHAMA"}</pre>

                </Typography>
            </Grid>
        </Grid>
    );
}

export default Success;
